import { defineStore } from "pinia";
import { CustomerSingle, CustomerStatus } from "~/types/customers";
import { Order } from "~/types/orders";
import {
  PurchaseOverviewItem,
  PurchaseOverviewItemActivity,
  PurchaseOverviewItemAuto,
  PurchaseOverviewItemAutoPayload,
  PurchaseOverviewItemManualPayload,
  PurchaseOverviewItemOutstanding,
} from "~/types/purchase-overview";
import { Meta, Option, PillVariant, ResponseType } from "~/types/general";
import { Filters, FilterType, letterFilterOptions } from "~/types/filters";
import { StatementStatus } from "~/types/statements";
import { useTasksStore } from "./tasks";
import { NotificationTag } from "~/types/notifications";

export const usePurchaseOverviewStore = defineStore("purchase-overview", () => {
  // State refs
  const purchaseOverviewItems = ref<PurchaseOverviewItem[]>([]);
  const meta = ref<Meta>({});

  const viewingActivities = ref<PurchaseOverviewItemActivity[]>([]);
  const viewingOutstanding = ref<PurchaseOverviewItemOutstanding[]>([]);

  const viewingCustomer = ref<CustomerSingle>({} as CustomerSingle);

  const selectedOrder = ref<Order>({} as Order);

  const selectedActivity = ref<PurchaseOverviewItemActivity>(
    {} as PurchaseOverviewItemActivity
  );
  const selectedOutstanding = ref<PurchaseOverviewItemOutstanding>(
    {} as PurchaseOverviewItemOutstanding
  );

  const selectedItemsIds = ref([]);

  const filters = ref<Filters>({
    statuses: {
      type: FilterType.TAG_LIST,
      title: "Status",
      options: [
        {
          name: "Active",
          value: StatementStatus.ACTIVE,
          type: PillVariant.GREEN,
        },
        {
          name: "Awaiting Payment",
          value: StatementStatus.AWAITING,
          type: PillVariant.GREY,
        },
        {
          name: "Overdue",
          value: StatementStatus.OVERDUE,
          type: PillVariant.RED,
        },
      ],
      chosenOptions: [],
    },
    supplierStatus: {
      type: FilterType.TAG_LIST,
      title: "Supplier Status",
      options: [
        {
          name: "Active",
          value: CustomerStatus.ACTIVE,
          type: PillVariant.GREEN,
        },
        {
          name: "Application",
          value: CustomerStatus.APPLICATION,
          type: PillVariant.GREEN,
        },
        {
          name: "Pending",
          value: CustomerStatus.PENDING,
          type: PillVariant.GREY,
        },
        {
          name: "Declined",
          value: CustomerStatus.BLOCKED,
          type: PillVariant.RED,
        },
        {
          name: "Archive",
          value: CustomerStatus.ARCHIVE,
          type: PillVariant.GREY,
        },
      ],
      chosenOptions: [],
    },
    suppliers: {
      type: FilterType.DEFAULT,
      title: "Suppliers",
      options: [],
      chosenOptions: [],
    },
    creditService: {
      type: FilterType.SWITCHER,
      title: "Credit Service",
      options: [],
      chosenOptions: [],
      toggle: false,
      isSingle: true,
    },
    suppliersLetter: {
      type: FilterType.LETTER,
      title: "Alphabet letter",
      options: letterFilterOptions,
      chosenOptions: [],
      isSingle: true,
      isApplied: false,
    },
    target_date: {
      type: FilterType.DATE_RANGE,
      title: "Target Date",
      options: [],
      chosenOptions: [],
      isSingle: true,
    },
    search: {
      type: FilterType.SEARCH,
      title: "Search",
      options: [],
      chosenOptions: [],
      isSingle: true,
    },
  });

  const targetDateRange = ref<string[]>([]);

  const autoPurchaseOverviewItems = ref<PurchaseOverviewItemAuto[]>([]);
  const viewingPurchaseOverviewItemAuto = ref<PurchaseOverviewItemAuto>(
    {} as PurchaseOverviewItemAuto
  );

  const isOpenSidebar = ref(false);
  const editMode = ref(false);

  const pageTable = ref(1);
  const perPageTable = ref(10);

  const selectedPurchaseOverviewItems = ref<number[]>([]);
  const templateMergeTags = ref(["User Name", "Statement", "Due Date"]);

  const selectedDate = ref("");
  const selectedDateRange = ref({
    start: "",
    end: "",
  });

  const mergeTagsList = ref([
    { name: "User Email", value: NotificationTag.USER_EMAIL },
    { name: "User Name", value: NotificationTag.USER_NAME },
    { name: "Supplier", value: NotificationTag.SUPPLIER },
    { name: "Statement", value: NotificationTag.STATEMENT },
    { name: "Period", value: NotificationTag.PERIOD },
    {
      name: "Outstanding Statement",
      value: NotificationTag.OUTSTANDING_STATEMENT,
    },
    {
      name: "Activities Statement",
      value: NotificationTag.ACTIVITIES_STATEMENT,
    },
    { name: "Payback Summary", value: NotificationTag.PAYBACK_SUMMARY },
    // { name: "Onboard Link", value: NotificationTag.ONBOARD_LINK },
  ]);

  // Getters
  const supplierFilterOptions = computed<Option[]>(() => {
    const suppliers = purchaseOverviewItems.value?.map(
      (purchaseOverviewItem) => ({
        name: purchaseOverviewItem.supplier?.name,
        value: purchaseOverviewItem.supplier?.id,
      })
    );

    return [
      ...new Map(suppliers.map((item) => [item.value, item])).values(),
    ].filter((supplier) => supplier.name);
  });

  // Actions
  // Fetching Purchase Overview Items
  async function getPurchaseOverviewItems(
    params?
  ): Promise<ResponseType<PurchaseOverviewItem[]>> {
    try {
      const response = await useVaniloApi("/statements/purchase-overview", {
        params,
      });

      return response as ResponseType<PurchaseOverviewItem[]>;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function getSinglePurchaseOverviewItemActivities(
    id: number,
    params?
  ): Promise<ResponseType<PurchaseOverviewItemActivity[]>> {
    try {
      const response = await useVaniloApi(
        `/statements/${id}/purchase-activities`,
        {
          params,
        }
      );

      return response as ResponseType<PurchaseOverviewItemActivity[]>;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function getSinglePurchaseOverviewItemOutstanding(
    id: number,
    params?
  ): Promise<ResponseType<PurchaseOverviewItemOutstanding[]>> {
    try {
      const response = await useVaniloApi(
        `/statements/${id}/purchase-outstanding`,
        {
          params,
        }
      );

      return response as ResponseType<PurchaseOverviewItemOutstanding[]>;
    } catch (error) {
      useCatchError(error);
    }
  }

  // Generating Manual Statement
  async function generateManualStatement(
    body?: PurchaseOverviewItemManualPayload
  ): Promise<ResponseType<PurchaseOverviewItem[]>> {
    try {
      const response = await useVaniloApi("/statements/generate", {
        method: "POST",
        body,
      });

      return response as ResponseType<PurchaseOverviewItem[]>;
    } catch (error) {
      useCatchError(error);
    }
  }

  // Auto Statements
  async function getAutoPurchaseOverviewItems(
    params?
  ): Promise<ResponseType<PurchaseOverviewItemAuto[]>> {
    try {
      const response = await useVaniloApi("/statements/templates", {
        params,
      });

      return response as ResponseType<PurchaseOverviewItemAuto[]>;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function getAutoSinglePurchaseOverviewItem(
    id: number
  ): Promise<ResponseType<PurchaseOverviewItemAuto>> {
    try {
      const response = await useVaniloApi(`/statements/templates/${id}`);

      return response as ResponseType<PurchaseOverviewItemAuto>;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function createAutoPurchaseOverviewItem(
    body?: PurchaseOverviewItemAutoPayload
  ): Promise<ResponseType<PurchaseOverviewItemAuto>> {
    try {
      const response = await useVaniloApi("/statements/templates", {
        method: "POST",
        body,
      });

      return response as Promise<ResponseType<PurchaseOverviewItemAuto>>;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function updateAutoPurchaseOverviewItem(
    id?: number,
    body?: PurchaseOverviewItemAutoPayload
  ): Promise<ResponseType<PurchaseOverviewItemAuto>> {
    try {
      const response = await useVaniloApi(`/statements/templates/${id}`, {
        method: "POST",
        body,
      });

      return response as ResponseType<PurchaseOverviewItemAuto>;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function deleteAutoPurchaseOverviewItem(id?: number) {
    try {
      const response = await useVaniloApi(`/statements/templates/${id}`, {
        method: "DELETE",
      });

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  // Sending Purchase Overview Items
  async function sendPurchaseOverviewItems(body: { customers: number[] }) {
    try {
      const response = await useVaniloApi(`/statements/send-statements`, {
        method: "POST",
        body,
      });

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  // Clearing Filters
  function clearFilters() {
    for (let d in filters.value) {
      filters.value[d].chosenOptions = [];
    }
  }

  async function bulkExportPurchaseOverview(payload) {
    useIsLoading(true);
    try {
      const response = (await useVaniloApi(
        `/statements/purchase-overview/export`,
        {
          method: "POST",
          body: payload,
        }
      )) as ResponseType<any>;

      useTasksStore().taskId = response.data.id;

      return response;
    } catch (error) {
      useCatchError(error);
    } finally {
      useIsLoading(false);
    }
  }

  async function bulkExportPurchaseReceiving(payload) {
    useIsLoading(true);
    try {
      const response = (await useVaniloApi(
        `/statements/purchase-overview/export-purchase-receiving`,
        {
          method: "POST",
          body: payload,
        }
      )) as ResponseType<any>;

      useTasksStore().taskId = response.data.id;

      return response;
    } catch (error) {
      useCatchError(error);
    } finally {
      useIsLoading(false);
    }
  }

  async function sendStatementsPurchaseOutstandingNew(body: {
    customers: number[];
    date_from?: string;
    date_to?: string;
  }) {
    try {
      const response = await useVaniloApi(
        `/statements/purchase-outstanding/new-send`,
        {
          method: "POST",
          body,
        }
      );
      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function sendStatementsPurchaseActivitiesNew(body: {
    customers: number[];
    date_from?: string;
    date_to?: string;
  }) {
    try {
      const response = await useVaniloApi(
        `/statements/purchase-activities/new-send`,
        {
          method: "POST",
          body,
        }
      );
      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function sendStatementsPaybackSummaryNew(body: {
    customers: number[];
    date_from?: string;
    date_to?: string;
  }) {
    console.log("test");
    try {
      const response = await useVaniloApi(
        `/statements/payback-summary/new-send`,
        {
          method: "POST",
          body,
        }
      );

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  return {
    // State
    purchaseOverviewItems,
    meta,
    viewingActivities,
    viewingOutstanding,
    viewingCustomer,
    selectedOrder,
    selectedActivity,
    selectedOutstanding,
    filters,
    targetDateRange,
    autoPurchaseOverviewItems,
    viewingPurchaseOverviewItemAuto,
    isOpenSidebar,
    editMode,
    pageTable,
    perPageTable,
    selectedPurchaseOverviewItems,
    templateMergeTags,
    selectedDate,
    selectedDateRange,
    selectedItemsIds,
    mergeTagsList,

    // Getters
    supplierFilterOptions,

    // Actions
    getPurchaseOverviewItems,
    getSinglePurchaseOverviewItemActivities,
    getSinglePurchaseOverviewItemOutstanding,
    generateManualStatement,
    getAutoPurchaseOverviewItems,
    getAutoSinglePurchaseOverviewItem,
    createAutoPurchaseOverviewItem,
    updateAutoPurchaseOverviewItem,
    deleteAutoPurchaseOverviewItem,
    sendPurchaseOverviewItems,
    clearFilters,
    bulkExportPurchaseOverview,
    bulkExportPurchaseReceiving,
    sendStatementsPurchaseOutstandingNew,
    sendStatementsPurchaseActivitiesNew,
    sendStatementsPaybackSummaryNew,
  };
});
